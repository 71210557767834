/**
 * original founction library
 * [usage]
 * import image from '@/assets/lib/image'
 * mixins: [image]
 */
import Vue from 'vue'

import store from '@/store'
import string from '@/assets/lib/string'

/**
 * @see https://github.com/blueimp/JavaScript-Load-Image
 */
import loadImage from 'blueimp-load-image'

export default Vue.extend({
  mixins: [string],
  methods: {
    /**
     * 画像ファイルをURLに変換する（SVG画像は除く）
     * @param {File} file 変換したいファイル情報
     * @param {String} path 格納先のパス名
     * @return {Object} { blob: Blobファイル, path: 格納先のパス名, url: ローカルのURL }
     */
    doImgProcess (file, path) {
      return new Promise(resolve => {
        if (!file) return resolve()

        // SVGか、画像以外のファイルが来た場合はテロップを表示してnullを返す
        const acceptFileType = ['png', 'jpg', 'jpeg', 'gif']
        if (!acceptFileType.includes(file.type.replace('image/', ''))) {
          store.commit('setTelop', { show: true, msg: '画像ファイル（SVGを除く）を選択してください。', type: 'warning' })
          return resolve()
        }

        loadImage.parseMetaData(file, data => {
          // exif情報から回転情報を取得
          const exif = data.exif ? data.exif.get('Orientation') : null

          // リサイズ用のオプション
          const options = {
            maxHeight: 1024,
            maxWidth: 1024,
            canvas: true
          }
          if (exif) options.orientation = exif

          // 画像容量が大きい場合を考慮して、品質調整
          const compressionRatio = file.size >= 1000000 ? 1000000 / file.size : 1

          // FileをURLに変換
          loadImage(
            file,
            canvas => {
              canvas.toBlob(blob => {
                resolve({
                  blob: blob,
                  path: path + this.getUniqueStr() + file.type.replace('image/', '.'),
                  url: window.URL.createObjectURL(blob)
                })
              },
              file.type,
              compressionRatio
              )
            },
            options
          )
        })
      })
    },
    /**
     * FileをBase64のDataURIにエンコードする
     * @param {File} file 変換したいファイル情報
     * @param {String} path 格納先のパス名
     * @return {String} { dataURI: Base64のDataURI , path: 格納先のパス名 }
     */
    imageToBase64 (file, path) {
      if (!file) return null

      return new Promise(resolve => {
        loadImage.parseMetaData(file, data => {
          // exif情報から回転情報を取得
          const exif = data.exif ? data.exif.get('Orientation') : null

          // リサイズ用のオプション
          const options = {
            maxHeight: 1024,
            maxWidth: 1024,
            canvas: true
          }
          if (exif) options.orientation = exif

          // 画像容量が大きい場合を考慮して、品質調整
          const compressionRatio = file.size >= 1000000 ? 1000000 / file.size : 1

          // FileをDataURIに変換
          loadImage(
            file,
            canvas => {
              const dataURI = canvas.toDataURL(file.type, compressionRatio)
              resolve({ dataURI: dataURI, path: path + this.getUniqueStr() + file.type.replace('image/', '.') })
            },
            options
          )
        })
      })
    },
    /**
     * DataURI(Base64)をBlobにデコードする
     * @param {String} dataURI デコードするDataURI
     * @return {Blob} デコードされたBlobファイル
     */
    base64ToImage (dataURI) {
      const byteString = atob( dataURI.split( ',' )[1] )
      const type = dataURI.match( /(:)([a-z\/]+)(;)/ )[2]

      // バッファーに変換
      const ab = new ArrayBuffer(byteString.length)
      const ua = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ua[i] = byteString.charCodeAt(i)
      }

      // blobに変換
      return new Blob([ua], { type: type })
    }
  }
})
