/**
 * original function library
 * [usage]
 * import string from '@/assets/lib/string'
 * mixins: string
 */
import Vue from 'vue'

export default Vue.extend({
  methods: {
    /**
     * 英数16桁のユニークな文字列を生成する。
     * @return {String} 英数16桁のユニークな文字列
     */
    getUniqueStr () {
      return new Date().getTime().toString(16) + Math.floor(1000 * Math.random()).toString(16)
    },
    /**
     * 正しいURLフォーマットか判定する
     * 具体的なパターンマッチの確認がしたい場合は下記サイトで入力
     * @see https://www.regextester.com/?fam=117238
     * @param {String} url 対象のURL
     * @return {Boolean} URLフォーマットが正しいかどうか
     */
    isValidURL (url) {
      const validUrlFormat = /((http|https):\/\/(\S){1,}\.[a-zA-Z]{2,}(\/(\S){1,}|\/)?)/g
      return url.match(validUrlFormat)
    },
    /**
     * @param {Number} digits 数字
     * @return {String} 丸で囲まれた数字（特殊文字）の生成
     */
    encircledNumber (digits) {
      const number = 9311 + digits
      const character = new DOMParser().parseFromString('&#' + number + ';', 'text/html')
      return character.documentElement.textContent
    }
  }
})
